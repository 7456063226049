<template>
  <div class="batteryIconWrapper">
    <!-- shows the battery level icon corresponding to the bikes acutal power level -->
    <img src="@/assets/icons/battery0.svg" class="batteryIcon" v-if="charging ? false : powerCalc === 0" alt="Power Level"/>
    <img src="@/assets/icons/battery1.svg" class="batteryIcon" v-if="charging ? false : powerCalc === 1" alt="Power Level"/>
    <img src="@/assets/icons/battery2.svg" class="batteryIcon" v-if="charging ? false : powerCalc === 2" alt="Power Level"/>
    <img src="@/assets/icons/battery3.svg" class="batteryIcon" v-if="charging ? false : powerCalc === 3" alt="Power Level"/>
    <img src="@/assets/icons/battery4.svg" class="batteryIcon" v-if="charging ? false : powerCalc === 4" alt="Power Level"/>
    <img src="@/assets/icons/battery_charging.svg" class="batteryIcon" v-if="charging" alt="Power Level"/>
  </div>
</template>

<script>
export default {
  name: 'BatteryIcon',
  data() {
    return {
      loaded: false,
    }
  },
  props: {
    power: Number,
    charging: Boolean,
  },
  computed: {
    powerCalc() {
      // calculates the number of bars inside the icon based on the battery percentage
      // this method gets called each time the power level changes
      const calc = Math.round(this.power * (4/100));
      console.log(calc);
      return calc;
    },
  }

}
</script>

<style scoped>
.batteryIconWrapper {
  height: 100%;
}
.batteryIcon {
  position: absolute;
  bottom: 0px;
  margin-bottom: .5rem;
  width: 2rem;
  height: 2rem;
  color: var(--visual-information-color);
  display: flex;
  align-items: center;
  vertical-align: middle;
}
</style>