<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <BackButton @click="$router.push('/')"/>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="9">
        <h1 style="margin-top: 4.25rem; margin-bottom: 2rem;">{{$store.state.bike.name}}</h1>
      </b-col>
      <b-col cols="1">
        <BatteryIcon v-if="$store.state.bike.type==='ebike'||1/* remove or*/" :charging="$store.state.bike.charging" :power="$store.state.bike.power"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <!-- only shows the detail data to debug users -->
    <b-row v-if="$store.state.userProfile.debug">
      <b-col cols="1"/>
      <b-col cols="10">
        <p>Standort: {{$store.state.bike.coordinates}}</p>
        <p>Typ: {{$store.state.bike.type}}</p>
        <p>Akkustand: {{$store.state.bike.power}}</p>
        <p>Charging: {{$store.state.bike.charging}}</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img src="@/assets/illustrations/bikergreenshirt.svg" class="illustration" alt="biker" style="width: 100%; height: 100%; margin-bottom: 2rem;"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row style="text-align: center;" class="flex-grow-1">
      <b-col cols="1"/>
      <b-col cols="10" class="justify-content-center align-self-center">
        <b-button variant="secondary" class="buttonSpacing" @click="reserveBike">Reservieren</b-button>
        <b-button variant="primary" class="buttonSpacing" @click="unlockBike">Entsperren</b-button>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <!-- only shows the detail data to debug users -->
      <p class="bikeDebugInfo" v-if="$store.state.userProfile.debug">{{$store.state.bike.ownerId}} &gt; {{$store.state.bike._id}}</p>
    </b-row>
  </b-container>
</template>

<script>
import BackButton from '../components/BackButton';
import BatteryIcon from '../components/BatteryIcon';
export default {
  name: 'BikeInfo',
  data() {
    return {
    }
  },
  components: {
      BackButton,
      BatteryIcon,
    },
  methods: {
    unlockBike() {
      this.$router.push('/biketutorial/1');
    },
    reserveBike() {
      this.$router.push('/bikereserveinfo');
    },
  },
  mounted() {
    // checks if the page loaded without any bike data
    if (Object.keys(this.$store.state.bike).length === 0) {
      this.$router.push('/').then(()=> this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger'));
    }
    // checks if the given bike is offline
    if ((Date.now() - this.$store.state.bike.lastSeen) >= 3600000) {
      this.$router.push('/').then(()=> this.toast('Es ist ein Fehler aufgetreten', 'Dieses Bike ist derzeit offline', 'danger'));
    }
  }
}
</script>

<style scoped>
.bikeDebugInfo {
  position: absolute;
  bottom: 0px;
  left: 20px;
} 
</style>